@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  padding: 0;
}
.ant-layout-sider-children ul {
  margin-top: 1rem;
}
@media screen and (min-width: 1024px) {
  .ant-layout-sider-children ul .ant-menu-item {
    padding-inline-start: 50px !important;
  }
}

input[type="checkbox"] {
  width: 1.2rem !important;
  height: 1.2rem !important;
  cursor: pointer;
}
